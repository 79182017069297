import { Component, Inject, LOCALE_ID, Renderer2, OnDestroy, OnInit, AfterViewInit } from '@angular/core';
import { ConfigService } from '../@vex/services/config.service';
import { Settings } from 'luxon';
import { DOCUMENT } from '@angular/common';
import { Platform } from '@angular/cdk/platform';
import { NavigationService } from '../@vex/services/navigation.service';
import icLayers from '@iconify/icons-ic/twotone-layers';
import icAssigment from '@iconify/icons-ic/twotone-assignment';
import icContactSupport from '@iconify/icons-ic/twotone-contact-support';
import icDateRange from '@iconify/icons-ic/twotone-date-range';
import icChat from '@iconify/icons-ic/twotone-chat';
import icContacts from '@iconify/icons-ic/twotone-contacts';
import icAssessment from '@iconify/icons-ic/twotone-assessment';
import icLock from '@iconify/icons-ic/twotone-lock';
import icWatchLater from '@iconify/icons-ic/twotone-watch-later';
import icError from '@iconify/icons-ic/twotone-error';
import icAttachMoney from '@iconify/icons-ic/twotone-attach-money';
import icPersonOutline from '@iconify/icons-ic/twotone-person-outline';
import icPerson from '@iconify/icons-ic/twotone-person';
import icReceipt from '@iconify/icons-ic/twotone-receipt';
import icHelp from '@iconify/icons-ic/twotone-help';
import icBook from '@iconify/icons-ic/twotone-book';
import icBubbleChart from '@iconify/icons-ic/twotone-bubble-chart';
import icFormatColorText from '@iconify/icons-ic/twotone-format-color-text';
import icStar from '@iconify/icons-ic/twotone-star';
import icViewCompact from '@iconify/icons-ic/twotone-view-compact';
import icPictureInPicture from '@iconify/icons-ic/twotone-picture-in-picture';
import icSettings from '@iconify/icons-ic/twotone-settings';
import { LayoutService } from '../@vex/services/layout.service';
import icUpdate from '@iconify/icons-ic/twotone-update';
import { ActivatedRoute } from '@angular/router';
import { filter, delay } from 'rxjs/operators';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { SplashScreenService } from '../@vex/services/splash-screen.service';
import { Style, StyleService } from '../@vex/services/style.service';
import theme from '../@vex/utils/tailwindcss';
import icChromeReaderMode from '@iconify/icons-ic/twotone-chrome-reader-mode';
import { ConfigName } from '../@vex/interfaces/config-name.model';
import { ChatService, AuthenticationService } from './_services';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { User } from './_models';
import icMenu from '@iconify/icons-ic/twotone-menu';

@Component({
  selector: 'vex-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = 'bfs-sp';
  latestChatId: number;
  unreadCnt = 0;
  currentUser: any;
  icMenu = icMenu;
  constructor(private configService: ConfigService,
              private styleService: StyleService,
              private renderer: Renderer2,
              private platform: Platform,
              @Inject(DOCUMENT) private document: Document,
              @Inject(LOCALE_ID) private localeId: string,
              private layoutService: LayoutService,
              private route: ActivatedRoute,
              private navigationService: NavigationService,
              private chatService: ChatService,
              private authenticationService: AuthenticationService,
              private splashScreenService: SplashScreenService) 
    {
      
    
      
      Settings.defaultLocale = this.localeId;

      if (this.platform.BLINK) {
        this.renderer.addClass(this.document.body, 'is-blink');
      }
    /**
     * Customize the template to your needs with the ConfigService
     * Example:
     *  this.configService.updateConfig({
     *    sidenav: {
     *      title: 'Custom App',
     *      imageUrl: '//placehold.it/100x100',
     *      showCollapsePin: false
     *    },
     *    showConfigButton: false,
     *    footer: {
     *      visible: false
     *    }
     *  });
     */

    /**
     * Config Related Subscriptions
     * You can remove this if you don't need the functionality of being able to enable specific configs with queryParams
     * Example: example.com/?layout=apollo&style=default
     */
      this.route.queryParamMap.pipe(
      filter(queryParamMap => queryParamMap.has('rtl') && coerceBooleanProperty(queryParamMap.get('rtl')))
    ).subscribe(queryParamMap => {
      this.document.body.dir = 'rtl';
      this.configService.updateConfig({
        rtl: true
      });
    });

      this.route.queryParamMap.pipe(
      filter(queryParamMap => queryParamMap.has('layout'))
    ).subscribe(queryParamMap => this.configService.setConfig(queryParamMap.get('layout') as ConfigName));

      this.route.queryParamMap.pipe(
      filter(queryParamMap => queryParamMap.has('style'))
    ).subscribe(queryParamMap => this.styleService.setStyle(queryParamMap.get('style') as Style));

}

ngOnInit(): void {
  //if(!localStorage.getItem('currentUser')) this.authenticationService.logout();
  this.navigationService.items = [
    {
      type: 'subheading',
      label: '',
      route: '/admin',
      icon: icMenu,
      children: [
        {
        type: 'dropdown',
          label: 'User Management',
          icon: icPerson,
          route: '',
          children: [
            {
              type: 'link',
              label: 'Active Users',
              icon: icPersonOutline,
              route: '/admin/users'
            },
            {
              type: 'link',
              label: 'Locked Users',
              icon: icError,
              route: '/admin/users/1'
            }
          ]
        },
        {
          type: 'dropdown',
          label: 'Grantees/Findings',
          icon: icUpdate,
          route: '/agency/grantees',
          children: [
            {
              type: 'link',
              label: 'Year ' + ((new Date()).getFullYear() - 1),
              route: '/agency/grantees/' + ((new Date()).getFullYear() - 1)
            },
            {
              type: 'link',
              label: 'Year ' + ((new Date()).getFullYear() - 2),
              route: '/agency/grantees/' + ((new Date()).getFullYear() - 2)
            },
            {
              type: 'link',
              label: 'Year ' + ((new Date()).getFullYear() - 3),
              route: '/agency/grantees/' + ((new Date()).getFullYear() - 3)
            }
          ]
        },
        {
          type: 'dropdown',
          label: 'Account Management',
          icon: icSettings,
          route: '',
          children: [
            {
              type: 'link',
              label: 'Create Bulk Users',
              icon: icContacts,
              route: '/admin/bulkprocess'
            }
          ]
        }
      ]
    },
    {
      type: 'subheading',
      label: '',
      route: '/agency',
      icon: icMenu,
      children: [
        {
          type: 'dropdown',
          label: 'Grantees',
          icon: icContacts,
          route: '/agency/grantees',
          children: [
            {
              type: 'link',
              label: 'Year ' + ((new Date()).getFullYear() - 1),
              route: '/agency/grantees/' + ((new Date()).getFullYear() - 1)
            },
            {
              type: 'link',
              label: 'Year ' + ((new Date()).getFullYear() - 2),
              route: '/agency/grantees/' + ((new Date()).getFullYear() - 2)
            },
            {
              type: 'link',
              label: 'Year ' + ((new Date()).getFullYear() - 3),
              route: '/agency/grantees/' + ((new Date()).getFullYear() - 3)
            }
          ]
        },
        {
          type: 'link',
          label: 'Communication',
          route: '/agency/chat/',
          icon: icChat,
          badge: {
            value: '',
            background: theme.colors.cyan['500'],
            color: theme.textColor['cyan-contrast']['600']
          }
        },
        {
          type: 'dropdown',
          label: 'Authentication',
          icon: icLock,
          route: '/agency',
          children: [
            {
              type: 'link',
              label: 'Reset Password',
              route: '/agency/forgot-password'
            },
            {
              type: 'link',
              label: 'My Profile',
              route: '/agency/myprofile'
            }
          ]
        },
        {
          type: 'link',
          label: 'Support',
          icon: icHelp,
          route: '/agency/support'
        }
      ]
    },
    {
      type: 'subheading',
      label: '',
      route: '/grantee',
      icon: icMenu,
      children: [
        {
          type: 'dropdown',
          label: 'Findings Report',
          icon: icAssigment,
          route: '',
          children: [
            {
              type: 'link',
              label: 'Year ' + ((new Date()).getFullYear() - 1),
              route: '/grantee/findings/' + ((new Date()).getFullYear() - 1)
            },
            {
              type: 'link',
              label: 'Year ' + ((new Date()).getFullYear() - 2),
              route: '/grantee/findings/' + ((new Date()).getFullYear() - 2)
            },
            {
              type: 'link',
              label: 'Year ' + ((new Date()).getFullYear() - 3),
              route: '/grantee/findings/' + ((new Date()).getFullYear() - 3)
            }
          ]
        },
        {
          type: 'link',
          label: 'Communication',
          route: '/grantee/chat/',
          icon: icChat
        },
        {
          type: 'dropdown',
          label: 'Authentication',
          icon: icLock,
          route: '/grantee/',
          children: [
            {
              type: 'link',
              label: 'Reset Password',
              route: '/grantee/forgot-password'
            },
            {
              type: 'link',
              label: 'My Profile',
              route: '/grantee/myprofile'
            }
          ]
        },
        {
          type: 'link',
          label: 'Support',
          icon: icHelp,
          route: '/grantee/support'
        }
      ]
    }
  ]
}
}
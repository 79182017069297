import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Chat, ChatMessage } from '../_models/';
import { Finding } from '../pages/apps/aio-table/interfaces/finding.model';

@Injectable({ providedIn: 'root' })
export class ChatService {
    
    constructor(private http: HttpClient) { }

    getAll() {
        return this.http.get<Chat[]>(`${config.apiUrl}/chat`);
    }

    getAllByGrantee(granteeId) {
        return this.http.get<Chat[]>(`${config.apiUrl}/chat/grantee/${granteeId}`);
    }

    getAllByAgency(agencyId) {
        return this.http.get<Chat[]>(`${config.apiUrl}/chat/agency/${agencyId}`);
    }

    getById(id: number) {
        return this.http.get<ChatMessage[]>(`${config.apiUrl}/chat/${id}`);
    }

    getByIdGrantee(id: number) {
        return this.http.get<ChatMessage[]>(`${config.apiUrl}/chat/chatgrantee/${id}`);
    }

    getLatestChatId() {
        return this.http.get<Chat>(`${config.apiUrl}/chat/sendat/latest`);
    }

    getAllUnread(agencyId) {
        return this.http.get(`${config.apiUrl}/chat/util/unreadcount/${agencyId}`);
    }

    getAllUnreadForGrantee(linkedentityid: any) {
        return this.http.get(`${config.apiUrl}/chat/util/unreadcountgrantee/${linkedentityid}`);
    }
  
    updateById(id: number) {
        return this.http.get<ChatMessage[]>(`${config.apiUrl}/chat/${id}`);
    }

    updateChat(chat: Chat) {
        return this.http.post(`${config.apiUrl}/chat/updatechat`, chat);
    }

    updateChatByGrantee(chat: Chat) {
        return this.http.post(`${config.apiUrl}/chat/updatechatByGrantee`, chat);
    }

    update(chatMessage: ChatMessage) {
        // console.log('Updated chatMessage:', chatMessage);
        return this.http.post(`${config.apiUrl}/chat/update`, chatMessage);
    }
}
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ChatService } from '../_services/chat.service';
import { Router } from '@angular/router';
import { User } from '../_models';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    public currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;

    private unreadCntSubject: BehaviorSubject<number>;
    public unreadCnt: Observable<number>;
    interval: any;

    constructor(private http: HttpClient, private chatService: ChatService, private router: Router) {

        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();

        this.unreadCntSubject = new BehaviorSubject<number>(0);
        this.unreadCnt = this.unreadCntSubject.asObservable();
    }

    public get currentUserValue(): User {
        // console.log('AUTH User',this.currentUserSubject.value);
        return this.currentUserSubject.value;
    }

    public get unreadCntValue(): number {
        // console.log('Unread Count',this.unreadCntSubject.value);
        
        return this.unreadCntSubject.value;
    }

    public changeUnreadCnt(n: any) {
        this.unreadCntSubject.next(n);
    }

    public set unreadCntValue(n: number) {
        this.unreadCntSubject.next(n);
    }

    login(username: string, password: string) {
        console.log('config.apiUrl' , config.apiUrl);
        this.unreadCntSubject.subscribe(uc => {
            this.unreadCntSubject.next(0);
                
        });
                
        return this.http.post<any>(`${config.apiUrl}/users/authenticate`, { username, password })
            .pipe(map(user => {
                console.log('User', user);
                // login successful if there's a jwt token in the response
                if (user && user.token) {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('currentUser', JSON.stringify(user));
                    this.currentUserSubject.next(user);
                   // console.log('Current User in Auth Service', user[0]);
      /*             
            */        
                    //this.refresh(this.currentUser);
                    
                
                }
               // this.unreadCntSubject.next(0);
                //Set this observable
                this.currentUser.pipe().subscribe(cu => {
                    console.log('THe current user is ...', cu);
                    if (cu[0].linkedentitytype.toLowerCase() !== 'admin') {
                        this.interval = setInterval(() => {
                        this.refresh(cu); // api call
                        }, 10000);
                    }
                });
                return user;
            }));
    }
    
    refresh(user: any) {
        if(!localStorage.getItem('currentUser'))
            clearInterval(this.interval);
        //this.unreadCntSubject.next(0);
        if(user[0].linkedentitytype === 'grantee') {
            this.chatService.getAllUnreadForGrantee(user[0].linkedentityid).pipe().subscribe(cnt => {
                console.log('UnreadCount of Messages for Grantee', cnt);
                if(cnt && cnt[0]) {
                   // localStorage.setItem('unreadCnt', cnt[0].cnt);
                    this.unreadCntSubject.next(cnt[0].cnt);
                
                }
            });
        } else {
            this.chatService.getAllUnread(user[0].linkedentityid).pipe().subscribe(cnt => {
                console.log('UnreadCount of Messages', cnt);
                if(cnt && cnt[0]) {
                 //   localStorage.setItem('unreadCnt', cnt[0].cnt);
                    this.unreadCntSubject.next(cnt[0].cnt);
                
                }
            });
        }
    }

    logout() {
        // remove user from local storage to log user out
        localStorage.clear();
        localStorage.removeItem('currentUser');
        this.currentUserSubject.next(null);
        //window.location.reload();
        //this.currentUserSubject.next(null);
        this.router.navigate(['/login']);
        
    }
    
   // ngOnDestroy() {
    //    this.unreadCnt.unsubscribe();
   // }
}
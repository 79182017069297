import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'vex-grantee',
  templateUrl: './grantee.component.html',
  styleUrls: ['./grantee.component.scss']
})
export class GranteeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

import { Role } from './role';

export class User {
    id: number;
    userName: string;
    password: string;
    firstName: string;
    lastName: string;
    phone: string;
    role: Role;
    token?: string;
    granteeid?: number;
    labels: any;
    active: any;
    linkedentitytype: string;
    linkedentityid: number;
    associatedTo: string;
    sendEmailNotification: boolean;

    constructor(user) {
        this.id = user.id;
        this.userName = user.username;
        this.firstName =  user.firstName;
        this.lastName = user.lastName;
        this.role = user.role;
        this.token = user.token;
        this.granteeid = user.granteeid;
        this.labels = user.labels;
        this.linkedentitytype = user.linkedentitytype;
        this.active = user.active;
        this.phone = user.phone;
        this.sendEmailNotification = user.sendEmailNotification;
    }
}
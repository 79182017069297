import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'vex-agency',
  templateUrl: './agency.component.html',
  styleUrls: ['./agency.component.scss']
})
export class AgencyComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import {Finding, FindingData} from '../agency/aio-table/interfaces/finding.model';
import {Observable} from 'rxjs';
import { User } from '../_models';

@Injectable({ providedIn: 'root' })
export class FindingService {
    
    getActionStatusCount(statusObj: any) {
        return this.http.post(`${config.apiUrl}/findings/correctiveaction/count`, statusObj);
    }

    getMDLCount(agencyObj: any) {
        return this.http.post(`${config.apiUrl}/findings/mdl/count`, agencyObj);
    }

    getAllByAgency(yearSelected: number, agencyid: any, limit: number, offset: number, event: any, search: any) {
        return this.http.get<FindingData[]>(`${config.apiUrl}/findings/agency/${yearSelected}/${agencyid}?limit=${limit}&offset=${offset}&sortBy=${event.active}&sortType=${event.direction}&search=${search}`);
    }

    showText(yearSelected: number, dbKey: any, type: string) {
        return this.http.get<FindingData[]>(`${config.apiUrl}/findings/agency/getText/${yearSelected}/${dbKey}/${type}`);
    }

    getAllByAdmin(yearSelected: number, limit: number, offset: number, event: any, search: any) {
        return this.http.get<FindingData[]>(`${config.apiUrl}/findings/admin/grantees/all/${yearSelected}?limit=${limit}&offset=${offset}&sortBy=${event.active}&sortType=${event.direction}&search=${search}`);
    }
  
    constructor(private http: HttpClient) { }

    getAll(year: number) {
        return this.http.get<Finding[]>(`${config.apiUrl}/findings/${year}`);
    }

    getAllByGrantee(year: number, granteeid: number, limit: number, offset: number, event: any, search: any) {
        return this.http.get<FindingData[]>(`${config.apiUrl}/findings/${year}/${granteeid}?limit=${limit}&offset=${offset}&sortBy=${event.active}&sortType=${event.direction}&search=${search}`);
    }

    getAllGrantee(search: any) {
        return this.http.get<Finding[]>(`${config.apiUrl}/findings/grantees/all?search=${search}`);
    }

    getAllAgency(search: any) {
        return this.http.get<Finding[]>(`${config.apiUrl}/findings/agencies/all?search=${search}`);
    }

    getAllGranteeName(yearSelected: number, search: any, agencyId: any, userType: any) {
        return this.http.get<FindingData[]>(`${config.apiUrl}/findings/admin/allGrantees/all/${yearSelected}/${agencyId}?search=${search}&userType=${userType}`);
    }

    getById(id: number) {
        return this.http.get<User>(`${config.apiUrl}/findings/${id}`);
    }

    getAuditByFindingId(f: any, year: number): Observable<any> {
        return this.http.get(`${config.apiUrl}/findings/harvester/${f.AUDITYEAR}/${f.EIN}`, {responseType: 'arraybuffer'});
    }

    getAuditByEIN(granteeId: number): Observable<any> {
        return this.http.get(`${config.apiUrl}/findings/harvester/2019/${granteeId}`, {responseType: 'blob'});
    }

    update(f: Finding) {
        return this.http.post(`${config.apiUrl}/findings/update`, f);
    }

    sendDecisionLetter(finding: Finding) {
        return this.http.post(`${config.apiUrl}/findings/sendDecisionLetter`, finding);
    }

    sendBulkMDL(mdlObj: any) {
        return this.http.post(`${config.apiUrl}/findings/sendBulkMDL`, mdlObj);
    }
    
    updateMessage(uf: Finding) {
        return this.http.post(`${config.apiUrl}/findings/sendMessage`, uf);
    }
    
    insertMessage(f: Finding) {
        return this.http.post(`${config.apiUrl}/chat/insert`, f);
    }

    insertNotes(uf: Finding) {
        return this.http.post(`${config.apiUrl}/findings/notes/insert`, uf);
    }

    insert(f: Finding) {
        return this.http.post(`${config.apiUrl}/findings/insert`, f);
    }

    showPreviousFindingData(findingData: any) {
        return this.http.get<Finding[]>(`${config.apiUrl}/findings/showPreviousFindingData/${findingData.year}/${findingData.agencyId}?ein=${findingData.ein}&repeatFinding=${findingData.repeatFinding}&dbkey=${findingData.dbkey}&type=${findingData.type}`);
    }
}

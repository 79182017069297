import { Component, ElementRef, HostBinding, Input, OnInit } from '@angular/core';
import { LayoutService } from '../../services/layout.service';
import icBookmarks from '@iconify/icons-ic/twotone-bookmarks';
import emojioneUS from '@iconify/icons-emojione/flag-for-flag-united-states';
import emojioneDE from '@iconify/icons-emojione/flag-for-flag-germany';
import icMenu from '@iconify/icons-ic/twotone-menu';
import { ConfigService } from '../../services/config.service';
import { map } from 'rxjs/operators';
import icPersonAdd from '@iconify/icons-ic/twotone-person-add';
import icHome from '@iconify/icons-ic/twotone-home';
import icAssignmentTurnedIn from '@iconify/icons-ic/twotone-assignment-turned-in';
import icBallot from '@iconify/icons-ic/twotone-ballot';
import icDescription from '@iconify/icons-ic/twotone-description';
import icAssignment from '@iconify/icons-ic/twotone-assignment';
import icReceipt from '@iconify/icons-ic/twotone-receipt';
import icDoneAll from '@iconify/icons-ic/twotone-done-all';
import { NavigationService } from '../../services/navigation.service';
import icArrowDropDown from '@iconify/icons-ic/twotone-arrow-drop-down';
import { PopoverService } from '../../components/popover/popover.service';
import { MegaMenuComponent } from '../../components/mega-menu/mega-menu.component';
import icSearch from '@iconify/icons-ic/twotone-search';
import icBusiness from '@iconify/icons-ic/twotone-business';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../../app/_services/authentication.service'
import { UserService } from 'src/app/_services';
import { User } from 'src/app/_models';
import { DateTime } from 'luxon';
import { Observable } from 'rxjs';
import _icHelp from '@iconify/icons-ic/twotone-help';

@Component({
  selector: 'vex-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit {

  @Input() mobileQuery: boolean;

  @Input()
  @HostBinding('class.shadow-b')
  hasShadow: boolean;
  currentYear: number = (new Date()).getFullYear() - 1;
  previousYear: number = (new Date()).getFullYear() - 2;
  twoPreviousYear: number = (new Date()).getFullYear() - 3;
  navigationItems = this.navigationService.items;
  currentUser: User;
  isHorizontalLayout$ = this.configService.config$.pipe(map(config => config.layout === 'horizontal'));
  isVerticalLayout$ = this.configService.config$.pipe(map(config => config.layout === 'vertical'));
  isNavbarInToolbar$ = this.configService.config$.pipe(map(config => config.navbar.position === 'in-toolbar'));
  isNavbarBelowToolbar$ = this.configService.config$.pipe(map(config => config.navbar.position === 'below-toolbar'));
  collapsedOpen$ = this.layoutService.sidenavCollapsedOpen$;
  showCollapsePin$ = this.configService.config$.pipe(map(config => config.sidenav.showCollapsePin));
  
  lastRefreshed?: Date;
  icSearch = icSearch;
  icBookmarks = icBookmarks;
  emojioneUS = emojioneUS;
  emojioneDE = emojioneDE;
  icMenu = icMenu;
  icPersonAdd = icPersonAdd;
  icAssignmentTurnedIn = icAssignmentTurnedIn;
  icBallot = icBallot;
  icDescription = icDescription;
  icAssignment = icAssignment;
  icReceipt = icReceipt;
  icDoneAll = icDoneAll;
  icArrowDropDown = icArrowDropDown;
  icHome = icHome;
  icBusiness = icBusiness;
  icHelp = _icHelp;
  dbUser: any = {};
  items = [];
  loggedInUsr: User;
  data$: Observable<User> = this.userService.subject$.asObservable();
  constructor(private layoutService: LayoutService,
              private configService: ConfigService,
              private navigationService: NavigationService,
              private authenticationService: AuthenticationService,
              private userService: UserService,
              private router: Router,
              private popoverService: PopoverService) {


               }

  ngOnInit() {
    this.authenticationService.currentUserSubject.pipe().subscribe(cu => {
      this.currentUser = cu;
    });
    this.userService.getById(this.currentUser[0].id).pipe().subscribe(u => {
      this.dbUser = u;
      console.log('DB User', u);
    });
    this.lastRefreshed = (new Date());
    this.userService.subject$.pipe().subscribe(user => {
      //this.currentUser[0] = user;      
      console.log('User updated in toolbar', user.firstName);
    });
    // console.log('Toolbar CurrentUser', this.currentUser[0]);
  }

  get filterByRole() {
    this.items = this.navigationService.items;
    const filteredresult = this.items.filter( x =>  x.route === '/' + this.currentUser[0].linkedentitytype.toLowerCase() );
    // // console.log('filteredresult', filteredresult);
    return filteredresult;
  }
  
  setLastRefreshed(e: any) {
    // console.log('CurrentTime', e);
    this.lastRefreshed = e;
  }

  changeRefresh(e: any) {
    
  }

  openQuickpanel() {
    this.layoutService.openQuickpanel();
  }

  openSidenav() {
    this.layoutService.openSidenav();
  }

  openMegaMenu(origin: ElementRef | HTMLElement) {
    this.popoverService.open({
      content: MegaMenuComponent,
      origin,
      position: [
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'top'
        },
        {
          originX: 'end',
          originY: 'bottom',
          overlayX: 'end',
          overlayY: 'top',
        },
      ]
    });
  }

  goToHome() {
    this.router.navigate(['/home']);
  }

  openSearch() {
    this.layoutService.openSearch();
  }

  openFindings(year: number) {
    if (this.currentUser && this.currentUser[0] && ( this.currentUser[0].linkedentitytype.toLowerCase() === 'agency' || this.currentUser[0].linkedentitytype.toLowerCase() === 'admin') ){
      this.router.navigate(['/agency/grantees/' + year]);
    } else if (this.currentUser && this.currentUser[0] && this.currentUser[0].linkedentitytype.toLowerCase() === 'grantee'){
      this.router.navigate(['/grantee/findings/' + year]);
    } 
      
  }
}

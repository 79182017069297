import { Component, Input, OnInit } from '@angular/core';
import { trackByRoute } from '../../utils/track-by';
import { NavigationService } from '../../services/navigation.service';
import icRadioButtonChecked from '@iconify/icons-ic/twotone-radio-button-checked';
import icRadioButtonUnchecked from '@iconify/icons-ic/twotone-radio-button-unchecked';
import { LayoutService } from '../../services/layout.service';
import { ConfigService } from '../../services/config.service';
import { map } from 'rxjs/operators';
import { ChatService, AuthenticationService } from '../../../app/_services'; 
import { User } from 'src/app/_models';
import { Router } from '@angular/router';

@Component({
  selector: 'vex-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit {

  @Input() collapsed: boolean;
  collapsedOpen$ = this.layoutService.sidenavCollapsedOpen$;
  title$ = this.configService.config$.pipe(map(config => config.sidenav.title));
  imageUrl$ = this.configService.config$.pipe(map(config => config.sidenav.imageUrl));
  imageUrlSmall$ = this.configService.config$.pipe(map(config => config.sidenav.imageUrlSmall));
  showCollapsePin$ = this.configService.config$.pipe(map(config => config.sidenav.showCollapsePin));

  items = this.navigationService.items;
  trackByRoute = trackByRoute;
  icRadioButtonChecked = icRadioButtonChecked;
  icRadioButtonUnchecked = icRadioButtonUnchecked;
  currentUser: User;
  showLogo: boolean = false;

  constructor(private navigationService: NavigationService,
              private layoutService: LayoutService,
              private route: Router,
              private chatService: ChatService,
              private authenticationService: AuthenticationService,
              private configService: ConfigService) {
                this.showLogo = false;

               }

  ngOnInit() {
    this.currentUser = this.authenticationService.currentUserValue;
    // this.getLatestChatId();
  }
  
  get filterByRole() {
    const filteredresult = this.items.filter( x =>  x.route === '/' + this.currentUser[0].linkedentitytype.toLowerCase() );
    // // console.log('filteredresult', filteredresult);
    return filteredresult;
  }

  goToHome() {
    this.route.navigate(['/home']);
  }

  onMouseEnter() {
    this.layoutService.collapseOpenSidenav();
  }

  onMouseLeave() {
    this.layoutService.collapseCloseSidenav();
  }

  toggleCollapse() {
    this.collapsed ? this.layoutService.expandSidenav() : this.layoutService.collapseSidenav();
  }

  getLatestChatId() {
    this.chatService.getLatestChatId().pipe().subscribe(c => {
      // console.log('Latest Chat: ', c);
      const latestChatId = c.id;
      this.items.find(ni => ni.label === 'Communication' && ni.type === 'link').route += latestChatId;
      // console.log('Latest ChatId: ', this.items.find(ni => ni.label === 'Agency Communication' && ni.type === 'link').route);
    });
  }
}

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { PopoverService } from '../popover/popover.service';
import { ToolbarNotificationsDropdownComponent } from './toolbar-notifications-dropdown/toolbar-notifications-dropdown.component';
import icNotificationsActive from '@iconify/icons-ic/twotone-notifications-active';
import { Router } from '@angular/router';
import { ChatService} from '../../../app/_services/chat.service';
import { AuthenticationService} from '../../../app/_services/authentication.service';
import { DateTime } from 'luxon';

@Component({
  selector: 'vex-toolbar-notifications',
  templateUrl: './toolbar-notifications.component.html',
  styleUrls: ['./toolbar-notifications.component.scss']
})
export class ToolbarNotificationsComponent implements OnInit {

  @ViewChild('originRef', { static: true, read: ElementRef }) originRef: ElementRef;

  dropdownOpen: boolean;
  numFlags = false;
  unreadCnt = 0;
  icNotificationsActive = icNotificationsActive;
  interval: any;
  latestChatId? = 0;
  currentUser: any;

  @Output() lastRefreshed? = new EventEmitter<Date>();
  
  constructor(private popover: PopoverService,
              private route: Router,
              private chatService: ChatService,
              private authenticationService: AuthenticationService,
              private cd: ChangeDetectorRef) {

              }

  ngOnInit() {
      // Get User
      this.currentUser = this.authenticationService.currentUserValue;
      this.lastRefreshed.emit(new Date());
      if (this.currentUser) {
        // console.log('Toolbar CurrentUser', currentUser[0]);
        this.unreadCnt = this.authenticationService.unreadCntValue;
        // console.log('Unread Message Count', this.unreadCnt);
        this.refresh();
      }
      
  }
  
  getLatestChatId() {
    this.chatService.getLatestChatId().pipe().subscribe(c => {
      // console.log('Latest Chat: ', c);
      this.latestChatId = c.id;
      // Use this to change the route link for the sidenav
      // this.items.find(ni => ni.label === 'Agency Communication' && ni.type === 'link').route += latestChatId;
      // console.log('Latest ChatId: ', this.items.find(ni => ni.label === 'Agency Communication' && ni.type === 'link').route);
    });
  }
  unreadCntObservable: any;
  refresh() {
    this.unreadCntObservable = this.authenticationService.unreadCnt.subscribe(r => {
      console.log('Toolbar Updated', r);
      this.unreadCnt = r;
    });
  }

  getNumFlags(){
    return (this.unreadCnt !=null && this.unreadCnt > 0);
  }
  
  openChat() {

    this.route.navigate(['/'+ this.currentUser[0].linkedentitytype +'/chat']);
  }

  ngOnDestroy() {
        this.unreadCntObservable.unsubscribe();
   }

  showPopover() {
    this.dropdownOpen = true;
    this.cd.markForCheck();

    const popoverRef = this.popover.open({
      content: ToolbarNotificationsDropdownComponent,
      origin: this.originRef,
      offsetY: 12,
      position: [
        {
          originX: 'center',
          originY: 'top',
          overlayX: 'center',
          overlayY: 'bottom'
        },
        {
          originX: 'end',
          originY: 'bottom',
          overlayX: 'end',
          overlayY: 'top',
        },
      ]
    });

    popoverRef.afterClosed$.subscribe(() => {
      this.dropdownOpen = false;
      this.cd.markForCheck();
    });
  }
}

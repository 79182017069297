import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { PopoverService } from '../popover/popover.service';
import { ToolbarUserDropdownComponent } from './toolbar-user-dropdown/toolbar-user-dropdown.component';
import icPerson from '@iconify/icons-ic/twotone-person';
import theme from '../../utils/tailwindcss';
import { first } from 'rxjs/operators';
import { User, Role } from '../../../app/_models';
import { UserService, AuthenticationService } from '../../../app/_services';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, of, ReplaySubject } from 'rxjs';

@Component({
  selector: 'vex-toolbar-user',
  templateUrl: './toolbar-user.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class ToolbarUserComponent implements OnInit {
  @Input() loggedUser: User;
  currentUser: User;
  userFromApi: User = new User({});
  dropdownOpen: boolean;
  icPerson = icPerson;
  
  data$: Observable<User> = this.userService.subject$.asObservable();
    
  theme = theme;
  testEmitter$: any;

  constructor(
      private userService: UserService,
      private authenticationService: AuthenticationService,
      private popover: PopoverService,
      private cd: ChangeDetectorRef,
      private router: Router
  ) {
      
  }

  ngOnInit() {
    this.authenticationService.currentUserSubject.pipe().subscribe(cu => {
      this.currentUser = cu[0];
    });
    console.log('Logged User', this.currentUser);
    
    //this.testEmitter$ = new BehaviorSubject<User>(this.currentUser[0]);
    this.userService.getById(this.currentUser.id).pipe(first()).subscribe(user => {
        this.userFromApi = user[0];
    });
    this.userService.subject$.pipe().subscribe(user => {
      //this.currentUser[0] = user;  
      this.currentUser = user;    
      console.log('User updated in toolbar-user', user.firstName);
    });
  }
  
  logout() {
      this.authenticationService.logout();
  }

  showPopover(originRef: HTMLElement) {
    this.dropdownOpen = true;
    this.cd.markForCheck();

    const popoverRef = this.popover.open({
      content: ToolbarUserDropdownComponent,
      origin: originRef,
      offsetY: 12,
      position: [
        {
          originX: 'center',
          originY: 'top',
          overlayX: 'center',
          overlayY: 'bottom'
        },
        {
          originX: 'end',
          originY: 'bottom',
          overlayX: 'end',
          overlayY: 'top',
        },
      ]
    });

    popoverRef.afterClosed$.subscribe(() => {
      this.dropdownOpen = false;
      this.cd.markForCheck();
    });
  }
}

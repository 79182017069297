import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { VexRoutes } from '../@vex/interfaces/vex-route.interface';
import { CustomLayoutComponent } from './custom-layout/custom-layout.component';
// Authentication Related
import { HomeComponent } from './home/home.component';
import { AdminComponent } from './admin/admin.component';
import { LoginComponent } from './login/login.component';
import { AuthGuard } from './_guards';
import { Role } from './_models';
import { AuthenticationService } from './_services';
import { ProfileComponent } from './pages/pages/profile/profile.component';

const childrenRoutes: VexRoutes = [
  {
    path: '',
    redirectTo: 'home', // + (new Date()).getFullYear(),
    pathMatch: 'full'
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/pages/profile/profile.module').then(m => m.ProfileModule),
    canActivate: [AuthGuard],
    pathMatch: 'full'
  },
  {
    path: 'agency',
    canActivate: [AuthGuard],
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./pages/pages/profile/profile.module').then(m => m.ProfileModule),
        data: {
          toolbarShadowEnabled: true
        }
      },
      {
        path: 'myprofile',
        loadChildren: () => import('./admin/components/profile-card/profile-card.module').then(m => m.ProfileCardModule),
        data: {
          toolbarShadowEnabled: true
        }
      },
      {
        path: 'grantees/:id',
        loadChildren: () => import('./agency/aio-table/aio-table.module').then(m => m.AioTableModule),
        data: {
          toolbarShadowEnabled: true
        }
      },
      {
        path: 'grantees',
        loadChildren: () => import('./agency/aio-table/aio-table.module').then(m => m.AioTableModule),
        data: {
          toolbarShadowEnabled: true
        }
      },
      {
        path: 'forgot-password',
        loadChildren: () => import('./forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule),
      },
      {
        path: 'register',
        loadChildren: () => import('./pages/pages/auth/register/register.module').then(m => m.RegisterModule),
      },
      {
        path: 'chat',
        loadChildren: () => import('./chat/chat.module').then(m => m.ChatModule),
        data: {
          toolbarShadowEnabled: true
        }
      },
      {
        path: 'chat/:id',
        loadChildren: () => import('./chat/chat.module').then(m => m.ChatModule),
      },
      {
        path: 'findings/:id',
        loadChildren: () => import('./pages/apps/aio-table/aio-table.module').then(m => m.AioTableModule),
      },
      {
        path: 'users',
        loadChildren: () => import('./admin/aio-table/aio-table.module').then(m => m.AioTableModule),
        data: {
          toolbarShadowEnabled: true
        }
      },
      {
        path: 'support',
        loadChildren: () => import('./pages/apps/help-center/help-center.module').then(m => m.HelpCenterModule),
        data: {
          toolbarShadowEnabled: true
        }
      }
    ]
  },
  {
    path: 'admin',
    canActivate: [AuthGuard],
    children: [
      {
        path: 'grantees',
        loadChildren: () => import('./agency/aio-table/aio-table.module').then(m => m.AioTableModule),
        data: {
          toolbarShadowEnabled: true
        }
      },
      {
        path: 'reports',
        loadChildren: () => import('./pages/pages/coming-soon/coming-soon.module').then(m => m.ComingSoonModule),
        data: {
          toolbarShadowEnabled: true
        }
      },
      {
        path: 'users',
        loadChildren: () => import('./admin/aio-table/aio-table.module').then(m => m.AioTableModule),
        data: {
          toolbarShadowEnabled: true
        }
      },
      {
        path: 'users/:id',
        loadChildren: () => import('./admin/aio-table/aio-table.module').then(m => m.AioTableModule),
        data: {
          toolbarShadowEnabled: true
        }
      },
      {
        path: 'bulkprocess',
        loadChildren: () => import('./admin/processed-users/aio-table.module').then(m => m.AioTableModule),
        data: {
          toolbarShadowEnabled: true
        }
      }
    ]
  },
  {
    path: 'grantee',
    canActivate: [AuthGuard],
    children: [
      {
        path: 'chat',
        loadChildren: () => import('./pages/apps/chat/chat.module').then(m => m.ChatModule),
        data: {
          toolbarShadowEnabled: true
        }
      },
      {
        path: 'forgot-password',
        loadChildren: () => import('./forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule),
      },
      {
        path: 'register',
        loadChildren: () => import('./pages/pages/auth/register/register.module').then(m => m.RegisterModule),
      },
      {
        path: 'chat/:id',
        loadChildren: () => import('./pages/apps/chat/chat.module').then(m => m.ChatModule),
      },
      {
        path: 'findings/:id',
        loadChildren: () => import('./pages/apps/aio-table/aio-table.module').then(m => m.AioTableModule),
      },
      {
        path: 'myprofile',
        loadChildren: () => import('./admin/components/profile-card/profile-card.module').then(m => m.ProfileCardModule),
        data: {
          toolbarShadowEnabled: true
        }
      },
      {
        path: 'support',
        loadChildren: () => import('./pages/apps/help-center/help-center.module').then(m => m.HelpCenterModule),
        data: {
          toolbarShadowEnabled: true
        }
      }
    ]
  }
];

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./pages/pages/auth/login/login.module').then(m => m.LoginModule),
  },
  {
    path: 'reset/:token',
    loadChildren: () => import('./reset-password/reset-password.module').then(m => m.ResetPasswordModule),
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./pages/pages/auth/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule),
  },
  {
    path: 'coming-soon',
    loadChildren: () => import('./pages/pages/coming-soon/coming-soon.module').then(m => m.ComingSoonModule),
  },
  {
    path: '',
    component: CustomLayoutComponent,
    children: childrenRoutes
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    // preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: 'enabled',
    relativeLinkResolution: 'corrected',
    anchorScrolling: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
